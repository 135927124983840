vue
<template>
    <div>
        <div class="p-grid">
            <div class="p-col-12 p-d-flex p-jc-center">
                <p class="title-bold">Acido hialurónico</p>
            </div>
        </div>

        <div class="p-grid">
            <div class="p-col-12 p-d-flex p-jc-center">
                <img class="img-fluid" alt="acido hialuronico" src="../../assets/acido.svg" />
            </div>

            <div class="p-col-10 p-offset-1 p-d-flex p-jc-center">
                <Card styleClass="p-card-shadow">
                    <template #content>
                        <p>
                            El <span class="p-text-bold">Ácido Hialurónico</span> es un tratamiento altamente versátil que
                            se adapta a las necesidades de cada paciente, ofreciendo una amplia gama de beneficios
                            estéticos. Con sus propiedades de dar volumen, rellenar arrugas, hidratar la piel con
                            mesoterapia y corregir líneas de expresión, el Ácido Hialurónico se convierte en la opción ideal
                            para rejuvenecer y mejorar la apariencia facial.
                        </p>
                        <p>
                            Nuestro tratamiento de Ácido Hialurónico no solo proporciona resultados inmediatos y naturales,
                            sino que también aporta luminosidad y recupera la elasticidad de la piel. Al rehidratar
                            profundamente la zona tratada, logramos un aspecto más terso, hidratado y rejuvenecido. ¡Luce
                            una piel radiante y saludable con nuestro tratamiento de Ácido Hialurónico!
                        </p>
                        <p>
                            En nuestra clínica, ofrecemos una variedad de opciones de tratamiento con Ácido Hialurónico para
                            abordar diferentes preocupaciones estéticas. Algunas de las áreas más comunes en las que se
                            utiliza incluyen:
                        </p>
                        <ul>
                            <li><span class="p-text-bold">Perfilado de labios</span>: Resalta y define tus labios para
                                conseguir una sonrisa perfecta y sensual.</li>
                            <li><span class="p-text-bold">Aumento de labios</span>: Consigue unos labios más voluminosos y
                                sensuales, mejorando su forma y proporción.</li>
                            <li><span class="p-text-bold">Aumento de pómulos</span>: Destaca tus pómulos y mejora la
                                estructura facial para lograr un aspecto más juvenil y definido.</li>
                            <li><span class="p-text-bold">Arrugas labiales</span> o (código de barras y comisuras): Suaviza
                                y elimina las arrugas alrededor de los labios, proporcionando un aspecto más joven y fresco.
                            </li>
                            <li><span class="p-text-bold">Arrugas perioculares externas</span> (patas de gallo): Reduce las
                                arrugas alrededor de los ojos, brindando una apariencia más rejuvenecida y luminosa.</li>
                            <li><span class="p-text-bold">Arrugas del entrecejo</span>: Minimiza las arrugas verticales
                                entre las cejas, logrando una apariencia más relajada y suave.</li>
                        </ul>
                        <p>
                            Nuestro equipo de expertos en estética facial está comprometido en brindarte una experiencia
                            personalizada y resultados excepcionales. Contamos con los conocimientos y la experiencia
                            necesarios para ayudarte a alcanzar tus objetivos estéticos de manera segura y efectiva. ¡No
                            dudes en contactarnos y descubre cómo el Ácido Hialurónico puede transformar tu apariencia!
                        </p>
                    </template>

                    <template #footer>
                        <span>Para más información no dude en ponerse en contacto con nuestro
                            Centro médico al 637 857 384.</span>
                    </template>
                </Card>
            </div>
        </div>
    </div>
</template>

<script>
import Card from 'primevue/card';
export default {
    name: 'AcidoHialuronicoVue',
    data() {
        return {
            // Propiedades de datos del componente
        };
    },
    components: {
        Card
    },
    methods: {
        // Métodos del componente
    },
    computed: {
        // Propiedades computadas del componente
    },
    created() {
        // Lógica que se ejecuta cuando el componente se crea
    },
    mounted() {
        // Lógica que se ejecuta cuando el componente se monta en el DOM
    },
    destroyed() {
        // Lógica que se ejecuta cuando el componente se destruye
    },
};
</script>

<style scoped>.title-bold {
    font-size: 30px;
    font-weight: bold
}

.img-fluid {
    width: 100%;
    height: auto;
}

@media (min-width: 576px) {
    .img-fluid {
        max-width: 50%;
        height: auto;
    }
}</style>